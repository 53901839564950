/* eslint-disable */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import Iconify from './Iconify';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log('error: ', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('errorInfo: ', errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            height: '70dvh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Iconify icon="solar:notes-broken" style={{ fontSize: '5rem', marginBottom: '1rem', color: '#919EAB' }} />
          <Typography variant="h4" style={{ maxWidth: '700px', mb: 2 }}>
            Something is broken
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 'normal', marginTop: 0, maxWidth: '500px', textAlign: 'center' }}
          >
            We've encountered an error while processing your request. Try reloading the page or consult with DebitCredit
            support team
          </Typography>
          <Box mt={3}>
            <Button onClick={() => window.location.reload()} variant="text">
              Reload page
            </Button>
          </Box>
        </Box>
      );
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
