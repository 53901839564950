// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/app/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'income & expense',
    path: '/app/income-expense',
    icon: getIcon('fa6-solid:money-bill-1'),
  },
  {
    title: 'owed & debt',
    path: '/app/persons',
    icon: getIcon('fluent:people-list-24-filled'),
  },
  {
    title: 'my goals',
    path: '/app/my-goals',
    icon: getIcon('lucide:goal'),
  },
  {
    title: 'my projects',
    path: '/app/my-projects',
    icon: getIcon('eva:folder-fill'),
  },
  {
    title: 'my subscriptions',
    path: '/app/my-subscriptions',
    icon: getIcon('material-symbols:subscriptions-rounded'),
  },
  {
    title: 'my categories',
    path: '/app/categories',
    icon: getIcon('eva:layers-fill'),
  },
];

export default navConfig;
