import { Navigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { BaseAPI } from '../utils/Api';
import { getItem, removeAll } from '../utils/Helper';

export const UserRegistration = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((registrationInfo) => BaseAPI.post('/auth/signup', registrationInfo), {
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const ConfirmOtpAfterRegistration = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((otpInfo) => BaseAPI.post('/auth/verify-otp', otpInfo), {
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const ConfirmMFACode = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((mfaInfo) => BaseAPI.post('/auth/verify-mfa', mfaInfo), {
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const UserLogin = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((signinInfo) => BaseAPI.post('/auth/signin', signinInfo), {
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const SendVerificationLinkToEmail = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((email) => BaseAPI.post('/auth/forgot-password', email), {
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const VerifyForgotPasswordLink = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((link) => BaseAPI.post('/auth/verify-reset-link', link), {
    select: (data) => data?.data?.data,
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((password) => BaseAPI.post('/auth/reset-password', password), {
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const ResendOtpCodeToEmail = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((email) => BaseAPI.post('/auth/resent-otp', email), {
    onError: (error) => {
      if (!error?.response?.data?.message && !error?.message) {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      } else if (error?.response?.data?.message === 'Session Expired') {
        removeAll(localStorage);
        <Navigate to="/signin" />;
      } else {
        enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
      }
    },
  });
};

export const LogoutFromApp = () =>
  useMutation((data) =>
    BaseAPI.post('auth/logout', data, {
      headers: { Authorization: `Bearer ${getItem(localStorage, 'at')}` },
    })
  );
