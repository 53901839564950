import Iconify from '../../components/Iconify';

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Features',
    icon: <Iconify icon={'eva:bulb-fill'} {...ICON_SIZE} />,
    path: '/features',
  },
  {
    title: 'Pages',
    path: '/pages',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Pricing', path: '/pricing' },
          { title: 'Contact us', path: '/contact-us' },
          { title: 'FAQs', path: '/questions' },
          { title: 'About us', path: '/about-us' },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Sign In', path: '/signin' },
          { title: 'Sign Up', path: '/signup' },
        ],
      },
    ],
  },
];

export default menuConfig;
