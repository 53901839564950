import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGurad from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import MainLayout from './layouts/main';

const OwedPersonList = lazy(() => import('./pages/OwedDebt/OwedPersonList'));
const DebtPersonList = lazy(() => import('./pages/OwedDebt/DebtPersonList'));
const Login = lazy(() => import('./pages/Auth/Login'));
const PersonList = lazy(() => import('./pages/OwedDebt/PersonList'));
const NotFound = lazy(() => import('./pages/Page404'));
const Register = lazy(() => import('./pages/Auth/Register'));
const DashboardApp = lazy(() => import('./pages/DashboardApp'));
const VerifyOTP = lazy(() => import('./pages/Auth/VerifyOTP'));
const VerifyMFA = lazy(() => import('./pages/Auth/VerifyMFA'));
const TryNewWayToLogin = lazy(() => import('./pages/Auth/TryNewWayToLogin'));
const IncomeAndExpense = lazy(() => import('./pages/IncomeExpense/IncomeAndExpense'));
const DetailsPersonTransaction = lazy(() => import('./pages/OwedDebt/DetailsPersonTransaction'));
const Profile = lazy(() => import('./pages/Account/Profile'));
const MyCategories = lazy(() => import('./pages/MyCategories'));
const DetailsIncomeAndExpense = lazy(() => import('./pages/IncomeExpense/DetailsIncomeAndExpense'));
const BudgetOfMonth = lazy(() => import('./pages/IncomeExpense/BudgetOfMonth'));
const Settings = lazy(() => import('./pages/Account/Settings'));
const ReportAndRequest = lazy(() => import('./pages/Account/ReportAndRequest'));
const Search = lazy(() => import('./pages/Search'));
const RateUs = lazy(() => import('./pages/Account/RateUs'));
const ConfirmEmail = lazy(() => import('./pages/Auth/ConfirmEmail'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const Home = lazy(() => import('./pages/Home'));
const ProjectList = lazy(() => import('./pages/MyProjects/ProjectList'));
const ProjectDetails = lazy(() => import('./pages/MyProjects/ProjectDetails'));
const RedirectedPage = lazy(() => import('./pages/RedirectedPage'));
const Features = lazy(() => import('./pages/Others/Features'));
const AboutUs = lazy(() => import('./pages/Others/AboutUs'));
const ContactUs = lazy(() => import('./pages/Others/ContactUs'));
const Faqs = lazy(() => import('./pages/Others/Faqs'));
const Pricing = lazy(() => import('./pages/Others/Pricing'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const Subscriptions = lazy(() => import('./pages/Subscriptions'));
const GoalList = lazy(() => import('./pages/MyGoals/GoalList'));
const ProjectActivity = lazy(() => import('./pages/MyProjects/ProjectActivity'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/app',
      element: (
        <AuthGurad>
          <DashboardLayout />
        </AuthGurad>
      ),
      children: [
        {
          path: 'dashboard',
          element: <DashboardApp />,
        },
        { path: 'persons', element: <PersonList /> },
        { path: 'owed-list', element: <OwedPersonList /> },
        { path: 'debt-list', element: <DebtPersonList /> },
        { path: 'persons/:id/details', element: <DetailsPersonTransaction /> },
        { path: 'income-expense', element: <IncomeAndExpense /> },
        { path: 'income-expense/:id/details', element: <DetailsIncomeAndExpense /> },
        { path: 'profile', element: <Profile /> },
        { path: 'categories', element: <MyCategories /> },
        { path: 'income-expense/budget/:id', element: <BudgetOfMonth /> },
        { path: 'settings', element: <Settings /> },
        { path: 'report-request', element: <ReportAndRequest /> },
        { path: 'rate-us', element: <RateUs /> },
        { path: 'search', element: <Search /> },
        { path: 'my-projects', element: <ProjectList /> },
        { path: 'project/:id/details', element: <ProjectDetails /> },
        { path: 'project/:id/activity', element: <ProjectActivity /> },
        { path: 'my-subscriptions', element: <Subscriptions /> },
        { path: 'my-goals', element: <GoalList /> },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <Home />
            </GuestGuard>
          ),
          index: true,
        },
        {
          path: 'features',
          element: <Features />,
        },
        {
          path: 'about-us',
          element: <AboutUs />,
        },
        {
          path: 'contact-us',
          element: <ContactUs />,
        },
        {
          path: 'questions',
          element: <Faqs />,
        },
        {
          path: 'pricing',
          element: <Pricing />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'verify-otp', element: <VerifyOTP /> },
        { path: 'verify-mfa', element: <VerifyMFA /> },
        { path: 'confirm-email', element: <ConfirmEmail /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'try-another-way', element: <TryNewWayToLogin /> },
        { path: 'redirect', element: <RedirectedPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'terms-and-conditions', element: <TermsAndConditions /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
