import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function NoConnection() {
  return (
    <>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" align="center">
              You are offline
            </Typography>
            <Typography variant="body1" align="center">
              Please connect with wifi or mobile internet to use DebitCredit
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}
