import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { BaseAPI } from '../utils/Api';
import { getItem, removeAll } from '../utils/Helper';

export const GetNotifications = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['notifications'],
    () =>
      BaseAPI.get('notification/list', {
        headers: { Authorization: `Bearer ${getItem(localStorage, 'at')}` },
      }),
    {
      select: (data) => data?.data?.data,
      onError: (error) => {
        if (!error?.response?.data?.message && !error?.message) {
          enqueueSnackbar('Something went wrong!', { variant: 'error' });
        } else if (error?.response?.data?.message === 'Session Expired') {
          removeAll(localStorage);
          navigate('/signin');
        } else {
          enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
        }
      },
    }
  );
};

export const MarkNotificationAsSeen = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (ids) =>
      BaseAPI.patch('notification/seen', ids, {
        headers: { Authorization: `Bearer ${getItem(localStorage, 'at')}` },
      }),

    {
      onSuccess: () => {
        queryClient.invalidateQueries('notifications');
      },
      onError: (error) => {
        if (!error?.response?.data?.message && !error?.message) {
          enqueueSnackbar('Something went wrong!', { variant: 'error' });
        } else if (error?.response?.data?.message === 'Session Expired') {
          removeAll(localStorage);
          navigate('/signin');
        } else {
          enqueueSnackbar(error?.response?.data?.message || error?.message, { variant: 'error' });
        }
      },
    }
  );
};
