import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

import Loading from './components/Loading';
import NoConnection from './components/NoConnection';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import useIsOnline from './hooks/useIsOnline';
import Router from './routes';
import ThemeProvider from './theme';

const queryClient = new QueryClient();

export default function App() {
  const online = useIsOnline();

  return (
    <Suspense fallback={<Loading />}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <NotistackProvider>
            <ScrollToTop />
            <BaseOptionChartStyle />
            {!online ? <NoConnection /> : <Router />}
            <ToastContainer />
          </NotistackProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </Suspense>
  );
}
