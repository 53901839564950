/* eslint-disable */
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';

import { GetNotifications, MarkNotificationAsSeen } from 'src/apis/Notification.api';
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import Scrollbar from '../../components/Scrollbar';

export default function NotificationsPopover() {
  const [open, setOpen] = useState(null);

  const { data: notifications } = GetNotifications();
  const { mutate: markAsSeen } = MarkNotificationAsSeen();

  const totalUnRead = notifications?.filter((item) => !item.is_seen).length;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    const filterUnReadIds = notifications?.filter((item) => !item.is_seen).map((unread) => unread.id);

    if (filterUnReadIds.length) {
      notificationSeenAction(filterUnReadIds);
    }
  };

  const notificationSeenAction = (ids) => {
    markAsSeen({ n_ids: ids });
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 240, sm: 340, lg: 500 } }}>
          <List disablePadding>
            {notifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onNotificationItemClick={notificationSeenAction}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    created_at: PropTypes.string,
    is_seen: PropTypes.bool,
    header: PropTypes.string,
    body: PropTypes.string,
  }),
  onNotificationItemClick: PropTypes.func,
};

function NotificationItem({ notification, onNotificationItemClick }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.is_seen && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => {
        if (!notification.is_seen) {
          onNotificationItemClick([notification.id]);
        }
      }}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {moment(notification.created_at).fromNow()}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <>
      <Typography variant="subtitle2">{notification.header}</Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {notification.body}
      </Typography>
    </>
  );

  return {
    title,
  };
}
