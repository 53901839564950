import { AES, enc } from 'crypto-js';

export const setItem = (type, key, value) => {
  try {
    type.setItem(key, AES.encrypt(value, process.env.REACT_APP_SECRET).toString());
    return true;
  } catch (error) {
    return false;
  }
};

export const getItem = (type, key, defaultValue) => {
  try {
    const encryptedValue = type.getItem(key);
    if (!encryptedValue) {
      return defaultValue;
    }
    const decryptedValue = AES.decrypt(encryptedValue, process.env.REACT_APP_SECRET).toString(enc.Utf8);
    return decryptedValue;
  } catch (error) {
    return defaultValue;
  }
};

export const removeItem = (type, key) => {
  type.removeItem(key);
};

export const removeAll = (type) => {
  type.clear();
};

export const getFirstLetter = (name) => {
  if (!name) return false;

  return name.charAt(0).toUpperCase();
};

export const toTitleCase = (str) => str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());

export const convertToPositiveNumber = (nmbr) => {
  if (nmbr < 0) {
    nmbr *= -1;
  }

  return nmbr;
};

export const maskEmail = (email) => {
  const atIndex = email.lastIndexOf('@');

  if (atIndex >= 0) {
    const prefix = email.substring(0, atIndex); // Characters before '@'
    const domain = email.substring(atIndex); // '@' and characters after

    // Replace characters in the prefix with asterisks, keeping the last 3 characters
    const maskedPrefix = '*'.repeat(prefix.length - 3) + prefix.slice(-3);

    return maskedPrefix + domain;
  }

  // If there's no '@' symbol in the email, return the original email
  return email;
};

export const greetingTime = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  let greeting = '';

  if (currentHour >= 5 && currentHour < 12) {
    greeting = 'Good morning';
  } else if (currentHour >= 12 && currentHour < 14) {
    greeting = 'Good noon';
  } else if (currentHour >= 14 && currentHour < 18) {
    greeting = 'Good afternoon';
  } else if (currentHour >= 18 && currentHour < 22) {
    greeting = 'Good evening';
  } else {
    greeting = 'Good late night';
  }

  return greeting;
};
