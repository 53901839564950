import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { getItem } from '../utils/Helper';
import { isValidToken } from '../utils/Jwt';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const token = getItem(localStorage, 'at');
  const { pathname } = useLocation();

  if (!isValidToken(token)) {
    return <Navigate to="/signin" state={pathname} />;
  }

  return <>{children}</>;
}
