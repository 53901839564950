import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;
  const INFO_MAIN = theme.palette.info.main;
  const WARNING_MAIN = theme.palette.warning.main;
  const ERROR_MAIN = theme.palette.error.main;
  const SUCCESS_MAIN = theme.palette.success.main;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 274 296" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="66.066" y="229.658" width="207.636" height="66.066" fill="url(#paint0_linear_101_17)" />
        <path
          d="M273.702 0L273.702 126.341L207.636 179.322L207.636 -2.88784e-06L273.702 0Z"
          fill="url(#paint1_linear_101_17)"
        />
        <path
          d="M66.066 179.322L66.066 295.724L5.43258e-06 235.95L7.90787e-06 179.322L66.066 179.322Z"
          fill="url(#paint2_linear_101_17)"
        />
        <path d="M62.92 113.256H207.636V179.322H0L62.92 113.256Z" fill="url(#paint3_linear_101_17)" />
        <defs>
          <linearGradient
            id="paint0_linear_101_17"
            x1="278.421"
            y1="261.118"
            x2="86.515"
            y2="261.118"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={ERROR_MAIN} />
            <stop offset="1" stopColor={WARNING_MAIN} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_101_17"
            x1="273.702"
            y1="89.661"
            x2="207.636"
            y2="89.661"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={ERROR_MAIN} />
            <stop offset="1" stopColor={WARNING_MAIN} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_101_17"
            x1="-18.876"
            y1="215.501"
            x2="72.358"
            y2="217.074"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={SUCCESS_MAIN} />
            <stop offset="1" stopColor={INFO_MAIN} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_101_17"
            x1="199.771"
            y1="144.716"
            x2="-3.38263e-06"
            y2="144.716"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={INFO_MAIN} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return { logo };
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
