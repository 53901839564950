import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import MenuPopover from '../../components/MenuPopover';
import Iconify from '../../components/Iconify';
import { getItem, removeAll } from '../../utils/Helper';
import { LogoutFromApp } from '../../apis/Auth.api';

const MENU_OPTIONS = [
  {
    label: 'My account',
    icon: 'eva:person-fill',
    linkTo: '/app/profile',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '/app/settings',
  },
  {
    label: 'Rate us',
    icon: 'eva:star-fill',
    linkTo: '/app/rate-us',
  },
  {
    label: 'Report a bug',
    icon: 'ic:round-bug-report',
    linkTo: '/app/report-request',
  },
];

export default function AccountPopover() {
  const theme = useTheme();

  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const { mutate, isLoading } = LogoutFromApp();

  useEffect(() => {
    setOpen(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    // mutate logout
    mutate(
      {},
      {
        onSuccess: () => {
          setOpen(null);
          removeAll(localStorage);
          removeAll(sessionStorage);
          navigate('/signin');
        },
        onError: () => {
          setOpen(null);
          removeAll(localStorage);
          removeAll(sessionStorage);
          navigate('/signin');
        },
      }
    );
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={`${process.env.REACT_APP_s3_cdn}/${process.env.REACT_APP_STAGE}${getItem(localStorage, 'dp')}`}
          alt="photoURL"
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {getItem(localStorage, 'fn')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {getItem(localStorage, 'ue')}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink}>
              <Iconify icon={option.icon} sx={{ ...ICON, color: theme.palette.grey[600] }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleClose} sx={{ m: 1, color: 'error.main' }} disabled={isLoading}>
          <Iconify
            icon={`${isLoading ? 'line-md:loading-twotone-loop' : 'material-symbols:logout-rounded'}`}
            sx={{ ...ICON }}
          />
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
